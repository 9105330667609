import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';;

const firebaseConfig = {
  apiKey: "AIzaSyDLH_vGiQKtrW-IIUeq5QZRmV_T1jJZwZo",
  authDomain: "rent-test-24344.firebaseapp.com",
  projectId: "rent-test-24344",
  storageBucket: "rent-test-24344.appspot.com",
  messagingSenderId: "378404012458",
  appId: "1:378404012458:web:a9f5ebf38c7c3a97826bd3",
  measurementId: "G-SDKZ7YEZGC"
};

firebase.initializeApp(firebaseConfig);

export default firebase;