import { useContext, useEffect, useState } from "react";
import MessageManager from "./Errors/Alert/MessageManager";
import Index from "./Routes";
import firebase from "./firebase";
import { Context } from "./Utils/context/Context";
import Notification from "./Layout/Components/Notification";
import sound from "./Static/sound/sound.mp3";

function App() {
  const { firebaseToken,setFirebaseToken } = useContext(Context);
  const [notification,setNotification] = useState({
    title: "",
    body: "",
  })
  useEffect(() => {
    const requestFirebaseNotificationPermission = async () => {
      const messaging = firebase.messaging();
      try {
        const token = await messaging.getToken();
        setFirebaseToken(token)
        // axiosInstance.post("notifications/fcm/", {
        //   registration_id: token,
        // });
        return token;
      } catch (error) {
        console.error("Error obtaining FCM token:", error);
      }
    };
    requestFirebaseNotificationPermission();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          const messaging = firebase.messaging();
          messaging.onMessage((payload) => {
          //  console.log(payload,"payload");
           setNotification({
            title: payload?.data?.title,
            body: payload?.data?.body,
           })
           const audio = new Audio(sound);
           audio.play();
          });
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);
  
  return (
    <div>
      <Notification data={notification} setNotification={setNotification} />
      <MessageManager/>
      <Index />
    </div>
  );
}
export default App;